import React, { useCallback } from 'react'
import { ConnectParams, toSearchParams } from './params'
import { ConnectParamsContext } from './paramsContext'

export const ConnectParamsProvider = ({
  connectParams,
  children,
}: {
  connectParams: ConnectParams
  children: React.ReactNode
}) => {
  const paramsReady = true
  const params = connectParams

  const appendParamsToLink = useCallback(
    (link: { path: string; params?: URLSearchParams }) => {
      const s = toSearchParams(params, link.params)
      return `${link.path}?${s.toString()}`
    },
    [params],
  )

  return (
    <ConnectParamsContext.Provider
      value={{
        paramsReady,
        params,
        appendParamsToLink,
      }}
    >
      {children}
    </ConnectParamsContext.Provider>
  )
}

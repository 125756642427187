import type { ParsedUrlQuery } from 'querystring'
import {
  CookieLoginParams,
  toSearchParams as cookieLoginParamsToSearchParams,
  getParams as getCookieLoginParams,
} from 'src/cookieLogin/params'
import {
  OIDCLoginParams,
  getParams as getOIDCLoginParams,
  toSearchParams as oidcLoginParamsToSearchParams,
} from 'src/oidc/params'
import {
  LoginPresetParams,
  getParams as getLoginPresetParams,
  toSearchParams as loginPresetToSearchParams,
} from './loginPresetParams'

export type ConnectParams = CookieLoginParams & OIDCLoginParams & LoginPresetParams

export const getParams = (query: ParsedUrlQuery): ConnectParams => {
  return {
    ...getCookieLoginParams(query),
    ...getOIDCLoginParams(query),
    ...getLoginPresetParams(query),
  }
}

export const toSearchParams = (params: ConnectParams, base?: URLSearchParams) => {
  let s = new URLSearchParams(base)
  if (params.oidc) {
    s = oidcLoginParamsToSearchParams(params, s)
  } else if (params.cookieLogin) {
    s = cookieLoginParamsToSearchParams(params, s)
  }

  if (params.loginPreset) {
    s = loginPresetToSearchParams(params, s)
  }

  return s
}

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://a53301f79437410c9564129122954a13@o112361.ingest.sentry.io/6541033',
  environment: process.env.AUTH_PF_ENV,
  enabled: Boolean(process.env.SENTRY_ENABLED),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})

import type { ParsedUrlQuery } from 'querystring'

// ログイン・初期登録時にフォームにプリセットするために受けとるパラメータ
// (SymView連携)
export type LoginPresetParams = {
  loginPreset: {
    email: string
  } | null
}

export const getParams = (query: ParsedUrlQuery): LoginPresetParams => {
  const { email } = query as {
    email: string | undefined
  }

  return {
    loginPreset: email
      ? {
          email,
        }
      : null,
  }
}

export const toSearchParams = (params: LoginPresetParams, base?: URLSearchParams) => {
  const s = new URLSearchParams(base)
  if (params.loginPreset) {
    s.append('email', params.loginPreset.email)
  }

  return s
}

// NOTE: 本番向けの bundle に STG の情報が含まれないようにビルド時に動的に差し替える

import { ConnectParams } from 'src/connect/params'
import { useConnectParams } from 'src/connect/useParams'

const defaultHost = process.env.AUTH_PF_PATIENT_API_HOST!

export const useHost = () => {
  const { params } = useConnectParams()
  return process.env.AUTH_PF_ENV === 'stg' ? overrideStgHostByParams(params) : defaultHost
}

// STGでは、ログイン要求元のプロダクトの環境に応じて、バックエンドの環境を動的に切り替える
const overrideStgHostByParams = (params: ConnectParams) => {
  if (params.cookieLogin?.redirectUri) {
    try {
      const url = new URL(params.cookieLogin.redirectUri)
      const env = url.host.match(/^((stg\d+)|(demo\d-[a-zA-Z0-9]+)).+/)?.[1]
      if (env) {
        return `https://${env}-api.stg.curon.co`
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return defaultHost
}

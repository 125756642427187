// クライアント（遷移元のプロダクト）の設定
// サーバ側で定義すべき値もありますが、現在はハードコードしています

export type ClientSetting = {
  id: ClientIds
  productName: string
  headerTitle: string
  notFoundRedirectTo?: string
}

export type ClientIds = 'default' | 'momo' | 'yap' | 'koala_web' | 'koala_app'

export const clientSettings: Record<ClientIds, ClientSetting> = {
  default: {
    id: 'default',
    productName: '__NAME__',
    headerTitle: 'オンライン診療サービスcuron（クロン）',
    notFoundRedirectTo: '/',
  },
  momo: {
    id: 'momo',
    productName: 'MOMO',
    headerTitle: 'クロンスマートパス',
    notFoundRedirectTo: process.env.COOKIE_LOGIN_MOMO_DEFAULT_CALLBACK_URL,
  },
  yap: {
    id: 'yap',
    productName: 'Yap',
    headerTitle: 'クロンお薬サポート',
    notFoundRedirectTo: process.env.COOKIE_LOGIN_YAP_DEFAULT_CALLBACK_URL,
  },
  koala_web: {
    id: 'koala_web',
    productName: 'KoalaWeb',
    headerTitle: 'オンライン診療サービスcuron（クロン）',
    notFoundRedirectTo: process.env.COOKIE_LOGIN_KOALA_WEB_DEFAULT_CALLBACK_URL,
  },
  koala_app: {
    id: 'koala_app',
    productName: 'KoalaApp',
    headerTitle: 'オンライン診療サービスcuron（クロン）',
    notFoundRedirectTo: process.env.COOKIE_LOGIN_KOALA_APP_DEFAULT_CALLBACK_URL,
  },
}

export const COOKIE_LOGIN_CLIENT_ID_MOMO = 'momo'

export const COOKIE_LOGIN_CLIENT_ID_YAP = 'yap'

export const COOKIE_LOGIN_CLIENT_ID_KOALA_WEB = 'koala_web'

export const COOKIE_LOGIN_CLIENT_ID_KOALA_APP = 'koala_app'

export const getClientSettingByCookieLoginClientId = (clientId: string) => {
  if (clientId === COOKIE_LOGIN_CLIENT_ID_MOMO) {
    return clientSettings.momo
  } else if (clientId === COOKIE_LOGIN_CLIENT_ID_YAP) {
    return clientSettings.yap
  } else if (clientId === COOKIE_LOGIN_CLIENT_ID_KOALA_WEB) {
    return clientSettings.koala_web
  }

  return getClientSettingByOidcClientId(clientId)
}

export const getClientSettingByOidcClientId = (clientId: string) => {
  if (clientId === COOKIE_LOGIN_CLIENT_ID_KOALA_APP) {
    return clientSettings.koala_app
  }

  return null
}

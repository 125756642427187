import React from 'react'
import { ConnectParams } from './params'

type ConnectParamsContextValue = {
  paramsReady: boolean
  params: ConnectParams
  appendParamsToLink: (link: { path: string; params?: URLSearchParams }) => string
}

export const ConnectParamsContext = React.createContext<ConnectParamsContextValue>({
  paramsReady: false,
  params: { cookieLogin: null, oidc: null, loginPreset: null },
  appendParamsToLink: (link: { path: string; params?: URLSearchParams }) => link.path,
})

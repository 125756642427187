import type { ParsedUrlQuery } from 'querystring'

export type OIDCLoginParams = {
  oidc: {
    clientId: string
    redirectUri: string
    responseType: string
    nonce: string
    state: string
    scope: string
  } | null
}

export const getParams = (query: ParsedUrlQuery): OIDCLoginParams => {
  const {
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: responseType,
    nonce,
    state,
    scope,
  } = query as {
    client_id: string | undefined
    redirect_uri: string | undefined
    response_type: string | undefined
    nonce: string | undefined
    state: string | undefined
    scope: string | undefined
  }

  return {
    oidc:
      clientId && redirectUri && responseType && nonce && state && scope
        ? {
            clientId,
            redirectUri,
            responseType,
            nonce,
            state,
            scope,
          }
        : null,
  }
}

export const toSearchParams = (params: OIDCLoginParams, base?: URLSearchParams) => {
  const s = new URLSearchParams(base)
  if (params.oidc) {
    s.append('client_id', params.oidc.clientId)
    s.append('redirect_uri', params.oidc.redirectUri)
    s.append('response_type', params.oidc.responseType)
    s.append('nonce', params.oidc.nonce)
    s.append('state', params.oidc.state)
    s.append('scope', params.oidc.scope)
  }

  return s
}
